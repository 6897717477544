import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height"},[_c(VRow,{staticClass:"fill-height",attrs:{"justify":"center","align":"center","align-content":"center"}},[_c(VCol,{staticClass:"fill-height",attrs:{"col":"12","sm":"4","align-self":"center"}},[_c(VRow,{staticClass:"fill-height",attrs:{"align":"center","justify":"center"}},[_c(VIcon,{staticClass:"mx-auto px-0 no-fish",attrs:{"color":"indigo","size":"180"}},[_vm._v(" mdi-fish ")])],1)],1),_c(VCol,{staticClass:"fill-height",attrs:{"col":"12","sm":"8"}},[_c(VRow,{staticClass:"fill-height",attrs:{"align":"center","justify":"center","align-content":"center"}},[_c(VCol,{attrs:{"cols":"12","align-self":"center"}},[_c('div',{staticClass:"display-4 primary--text font-weight-regular"},[_vm._v(" 404 ")])]),_c(VCol,{staticClass:"mb-10",attrs:{"cols":"12","align-self":"center"}},[_c('div',{staticClass:"primary--text headline"},[_vm._v(" Sajnáljuk, a keresett oldal nem lézetik. "),_c(VImg,{staticStyle:{"visibility":"hidden!important"},attrs:{"height":"1px","width":"1px","src":"/page404.png"}})],1)]),_c(VCol,{attrs:{"cols":"12"}},[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({name: 'home'})}}},[_vm._v(" Vissza a kezdőlapra ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }