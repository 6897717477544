<template>
  <v-container class="fill-height">
    <v-row justify="center" align="center" align-content="center" class="fill-height">
      <v-col col="12" sm="4" align-self="center" class="fill-height">
        <v-row align="center" justify="center" class="fill-height">
          <v-icon color="indigo" size="180" class="mx-auto px-0 no-fish">
            mdi-fish
          </v-icon>
        </v-row>
      </v-col>
      <v-col col="12" sm="8" class="fill-height">
        <v-row align="center" justify="center" class="fill-height" align-content="center">
          <v-col cols="12" align-self="center">
            <div class="display-4 primary--text font-weight-regular">
              404
            </div>
          </v-col>
          <v-col cols="12" align-self="center" class="mb-10">
            <div class="primary--text headline">
              Sajnáljuk, a keresett oldal nem lézetik.
              <v-img height="1px" width="1px" :src="`/page404.png`" style="visibility: hidden!important"></v-img>
            </div>
          </v-col>
          <v-col cols="12">
            <v-btn color="primary" @click="$router.push({name: 'home'})">
              Vissza a kezdőlapra
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'NoPage',
  props: {
    //
  },
  data() {
    return {
      //
    };
  },
  methods: {
    //
  },
};
</script>

<style scoped>

</style>
